.App {
  text-align: center;
  background-color: #F7F5F1;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

/* unvisited link */
a:link {
  color: black;
}

/* visited link */
a:visited {
  color: green;
}

/* mouse over link */
a:hover {
  color: green;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.Header-background {
  background-color: #47cf50;
  height: 75px;
  /* margin: 0 -20px; */
  display: flex;
  align-items: center;
  flex-direction: column;
}

.Header-text {
  margin: 0 auto;
  font-family: Graphik web, sans-serif;
}

.content {
  display: flex;
  align-items: center;
  flex-direction: column;
  flex-wrap: wrap;
}

/* LEXPOST COMPONENT BELOW */


.LexPost {
  margin-top: 20px;
  margin-bottom: 20px;
  border-radius: 25px;
  border: 2px solid #fff;
  background-color: #fff;
  width: 400px;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.LexPost__header {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.LexPost__header-left {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 20px;
  font-size: small;
}

.LexPost__header-right {
  margin-right: 20px;
}

.LexPost__content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 20px;
}

.LexPost__title {
  font-weight: bold;
  font-size: larger;
  margin-bottom: 5px;
  text-align: left;
}

.LexPost__description {
  margin-top: 0px;
  text-align: left;
  font-size: medium;
}

.LexPost__Link {
  text-align: left;
}

.LexPost__footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 10px;
  
}

.LexPost__footer-left {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-left: 20px;
}

.LexPost__footer-right {
  align-items: flex-end;
  margin-right: 20px
}

.LexPost__footer-box {
  border-radius: 10px;
  border: 2px solid #000;
  padding: 10px;
  width: 40px;
  height: 20px;
}


/* LEXBREAK COMPONENT BELOW */


.LexBreak {
  margin-top: 20px;
  margin-bottom: 20px;
  border-radius: 25px;
  border: 2px solid #EDBAFA;
  background-color: #EDBAFA;
  color: #000;
  width: 400px;
  height: auto;
  display: flex;
  flex-direction: column;
}

.LexBreak__content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.LexBreak__title {
  font-weight: bold;
  font-size: larger;
  margin-top: 0px;
  margin-bottom: 5px;
  text-align: left;
}

.LexBreak__description {
  margin-top: 0px;
  font-size: medium;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
